import { FaMobileAlt } from "react-icons/fa";
import { MdEmail, MdLocationPin } from "react-icons/md";

export const LANDING_DATA = {
  email: {
    label: "Email",
    icon: <MdEmail />,
    value: "vishnusmindclinic@gmail.com",
  },
  mobile: {
    label: "Mobile",
    icon: <FaMobileAlt />,
    value: ["+91 93445 41780", "+91 86675 26125"],
  },
  address: {
    label: "Address",
    icon: <MdLocationPin />,
    value: [
      "Dr.Mithun's Diagnostic Centre",
      "No.48, 120 ft road,",
      "Vivekananda Nagar, K.Pudur,",
      "Madurai-625007",
    ],
  },
};
