import React from "react";
import { LANDING_DATA } from "./landingData";
import styles from "./Landing.module.css";
import { PiWarningCircleFill } from "react-icons/pi";

function Landing() {
  const { mobile, email, address } = LANDING_DATA;
  const clinicLogoSrc = `${process.env.PUBLIC_URL}/assets/images/clinic_logo.jpeg`;

  return (
    <div className={`${styles.outer_wrapper}`}>
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.image_container}`}>
          <img src={clinicLogoSrc} alt="Vishnu's Mind Clinic Logo" />
        </div>

        <div className={`${styles.text_container}`}>
          {/* Name */}
          <div className={`${styles.name_textbox}`}>
            <h1>Dr.P.R.SATHISH SANKAR</h1>
          </div>

          {/* Mobile Number */}
          <div className={`${styles.mobile_textbox}`}>
            <div className={`${styles.icon_box}`}>{mobile.icon}</div>
            <div>
              {mobile.value.map((value, index) => {
                return <p key={index}>{value}</p>;
              })}
            </div>
          </div>

          {/* Email */}
          <div className={`${styles.email_textbox}`}>
            <div className={`${styles.icon_box}`}>{email.icon}</div>
            <div>
              <p>{email.value}</p>
            </div>
          </div>

          {/* Address */}
          <div className={`${styles.address_textbox}`}>
            <div className={`${styles.icon_box}`}>{address.icon}</div>
            <div>
              {address.value.map((value, index) => {
                return <p key={index}>{value}</p>;
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Site Under Construction Banner */}
      <div className={`${styles.construction_wrapper}`}>
        <p>
          <PiWarningCircleFill className={`${styles.construction_icon}`} />
          &nbsp;The website is under construction!
        </p>
      </div>
    </div>
  );
}

export default Landing;
